import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import Fallback from "./components/extra/fallback";

const Main = lazy(() => import("./main"));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Suspense fallback={<Fallback text="Loading App" />}>
    <Main />
  </Suspense>
);
