import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import Title from "antd/es/typography/Title";
import React from "react";

const Fallback = ({ text = "Loading" }) => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        zIndex: 999,
        position: "fixed",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: "5rem" }} />} />
        <Title level={4} style={{ margin: "15px 0px" }}>
          {text}
        </Title>
      </div>
    </div>
  );
};

export default Fallback;
